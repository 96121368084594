/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~simple-line-icons/css/simple-line-icons.css';
@import '~weather-icons/css/weather-icons.css';
@import '~weather-icons/css/weather-icons-wind.css';

@import '~loaders.css/loaders.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~jqcloud2/dist/jqcloud.css';

@import '~summernote/dist/summernote.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";
//== NgSelect
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

// == Google Web Font - Raleway
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

// == Font Awesome
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";

body, html{
  font-family: 'Raleway', sans-serif;
}

/* BOOTSTRAP 5 */

div:where(.swal2-container) button:where(.swal2-styled).swal2.confirm {
  background-color: $primary !important;
}

.modal.fade.show {
  opacity: 1;
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-backdrop.show {
  opacity: .5;
}

/* MODAL HEADER ALIGNMENT AFTER UPDATE */
.modal-header {
  display: flex;
  justify-content: space-between;

  >.modal-title {
    flex: 1;
  }
}

.swal2-loading {
  padding: 1rem 0;
}

/* REMOVED BORDER FROM PASSENGER TYPE SELECTION +/- BUTTONS */
.input-group-btn > .btn-number {
  border: unset;

  &:hover {
    color: rgba(0, 0, 0);
  }
}